<template>
    <main class="main-page" id="">
        <section class="page-section q-pa-md" >
            <div class="container-fluid">
                <div class="grid ">
                    <div  class="col comp-grid" >
                        <div class=" text-lg font-bold text-primary" >
                            Admin
                        </div>
                        <hr class="my-3" />
                    </div>
                </div>
            </div>
        </section>
        <section class="page-section mb-3" >
            <div class="container-fluid">
                <div class="grid ">
                    <div  class="col comp-grid" >
                        <record-count api-path="components_data/getcount_menungguvalidasi" max="" v-slot="record">
                        <router-link  :to="`/pembayaran/validasi_pembayaran`">
                            <div class="card text-white bg-primary" >
                                <div class="font-medium text-lg mb-3">Menunggu Validasi</div>
                                <div class="flex align-items-center justify-content-between mb-3">
                                    <div>
                                        <div class="font-bold text-4xl" v-if="!record.loading">{{ record.num }}</div>
                                        <ProgressSpinner v-else style="width:20px;height:20px" />
                                    </div>
                                    <div style="width:auto;">
                                        <Avatar icon="pi pi-check-circle" size="xlarge" class="" />
                                        </div>
                                    </div>
                                    <div class="text-500 text-sm"></div>
                                </div>
                            </router-link>
                            </record-count>
                        </div>
                    </div>
                </div>
            </section>
            <section class="page-section mb-3" >
                <div class="container-fluid">
                    <div class="grid ">
                        <div  class="col comp-grid" >
                            <record-count api-path="components_data/getcount_daftarorder" max="" v-slot="record">
                            <router-link  :to="`/pembayaran/list_admin`">
                                <div class="card " >
                                    <div class="font-medium text-lg mb-3">Daftar Order</div>
                                    <div class="flex align-items-center justify-content-between mb-3">
                                        <div>
                                            <div class="font-bold text-4xl" v-if="!record.loading">{{ record.num }}</div>
                                            <ProgressSpinner v-else style="width:20px;height:20px" />
                                        </div>
                                        <div style="width:auto;">
                                            <Avatar icon="pi pi-verified" size="xlarge" class="" />
                                            </div>
                                        </div>
                                        <div class="text-500 text-sm"></div>
                                    </div>
                                </router-link>
                                </record-count>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        
</template>
<script setup>
	import {  ref } from 'vue';
	
	
	import { useApp } from 'src/composables/app.js';
	
	
	
	const props = defineProps({
		pageName: {
			type: String,
			default: 'home',
		},
		routeName: {
			type: String,
			default: 'home',
		},
		isSubPage: {
			type : Boolean,
			default : false,
		},
	});
	const app = useApp();
	
	const pageReady = ref(true);
	
</script>
<style scoped>
</style>
